<script setup lang="ts">
import type { Schemas } from "#shopware";
import { CART_ERROR_MESSAGES } from "~/lib/constants";

const { cart } = useCart();

const errors = computed(() => cart.value?.errors as Schemas["CartError"][]);

const getErrorMessage = (error: Schemas["CartError"]) => {
	const messageConstant = CART_ERROR_MESSAGES[error.messageKey] || CART_ERROR_MESSAGES[error.key];
	if (messageConstant) {
		return messageConstant.message;
	}

	if (error.message) {
		return error.message;
	}

	return `Onbekende fout ${error.messageKey}`;
};

const getErrorSolution = (error: Schemas["CartError"]) => {
	const messageConstant = CART_ERROR_MESSAGES[error.messageKey] || CART_ERROR_MESSAGES[error.key];

	if (!error.messageKey || !messageConstant) return;

	return messageConstant.solution;
};
</script>

<template v-if="errors && errors.length">
	<div
		v-for="error in errors"
		:key="error.key"
		class="p-4 rounded text-sm flex gap-2 items-center"
		:class="{
			'bg-red text-white': error.level === 20,
			'bg-sand': error.level === 10,
			'border border-brown': error.level === 0
		}"
	>
		<SvgIcon name="info" class="min-w-[24px]" />
		<span>
			{{ getErrorMessage(error) }}
			<component :is="getErrorSolution(error)" />
		</span>
	</div>
</template>
